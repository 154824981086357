import React, { useContext, useEffect, useState, useRef, Fragment } from "react";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import Countdown from "react-countdown-now";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

import { Loading } from "../../../components/UI/Loading";

export default (props) => {
	const { location, streams, onSetNowStream } = props;
	const { auth, cfg } = useContext(AppContext);
	const { idx: siteIdx, event } = cfg;
	const { idx: siteEventIdx } = event;

	const [nowStream, setNowStream] = useState(props.nowStream);
	const [isLogined, setIsLogined] = useState(false);
	const [isLoadSession, setIsLoadSession] = useState(false);
	const [sessions, setSessions] = useState([]);
	const [session, setSession] = useState(null);
	const [quiz, setQuiz] = useState(null);
	const [question, setQuestion] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	const loadSessions = () => {
		_API
			.get({
				path: "/v1/site/event/sessions/room",
				data: {
					siteIdx,
					siteEventIdx,
					siteLocationDtlIdx: location,
				},
			})
			.then((res) => {
				const { startDate, endDate, list } = res.data;
				setStartDate(startDate);
				setEndDate(endDate);
				setSessions(list);
				setIsLoadSession(true);
			});
	};

	const selectSession = () => {
		if (!isLoadSession || sessions.length === 0) {
			setSession(null);
			return;
		}

		const now = moment();
		const day = now.format("YYYY-MM-DD");
		const fs = sessions.filter((s) => {
			const start = moment(`${day} ${s.startTime}`, "YYYY-MM-DD HH:mm:ss");
			const end = moment(`${day} ${s.endTime}`, "YYYY-MM-DD HH:mm:ss");
			return now.isBetween(start, end);
		});
		if (fs.length === 0) {
			setSession(null);
			return;
		}
		setSession(fs[0]);
	};

	const sendAlive = () => {
		if (!session || (event.useLogin === "Y" && !isLogined)) {
			return;
		}
		if (event.useLogin === "Y") {
			_API
				.post({
					path: "/v1/site/event/session/alive",
					data: {
						siteIdx,
						siteEventIdx,
						siteEventSessionIdx: session.idx,
					},
				})
				.then((res) => {});
		}
		_U.postMessage("SOCKET-SEND", {
			action: "ROOM",
			data: {
				type: "MEMBER",
				room: location,
			},
		});
	};

	const onQuestion = () => {
		if (!session || !question) {
			return;
		}

		_API
			.post({
				path: "/v1/site/event/session/question",
				data: {
					siteIdx,
					siteEventIdx,
					siteEventSessionIdx: session.idx,
					question: question,
				},
			})
			.then((res) => {
				_U.postMessage("SOCKET-SEND", {
					action: "QUESTION",
					data: {
						room: location,
					},
				});
				setQuestion("");
				swal({ title: "알림", text: "질문을 등록했습니다." });
			});
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e)
			.then((data) => {
				switch (data.code) {
					case "SOCKET-RECEIVED":
						return data.data ? JSON.parse(data.data) : null;
				}
			})
			.then((data) => {
				if (data?.mode === "QUIZ") {
					setQuiz(data.quiz);
				}
			});
	};

	useEffect(() => {
		if (_CK.get("stream")) {
			setNowStream(_CK.get("stream"));
			_CK.set("stream", "");
		}

		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	useEffect(() => {
		if (event.useLogin === "Y" && !_CK.isLogin()) {
			swal({ title: "알림", text: "로그인 후 이용이 가능합니다." }).then(() => {
				window.location.href = "#/";
			});
			return;
		}
		loadSessions();
		setIsLogined(_CK.isLogin());
	}, [auth]);

	useEffect(() => {
		if (isLoadSession) {
			selectSession();
			const ss = setInterval(selectSession, 10000);
			return () => clearInterval(ss);
		}
	}, [isLoadSession]);

	useEffect(() => {
		if (session) {
			sendAlive();
			const sa = setInterval(sendAlive, 10000);
			return () => clearInterval(sa);
		}
	}, [session]);

	useEffect(() => {
		if (quiz) {
			const time = quiz?.time ? quiz.time * 1000 : 15000;

			setTimeout(() => {
				setQuiz(null);
			}, time);
		}
	}, [quiz]);

	if (event.useLogin === "Y" && !_CK.isLogin()) {
		return null;
	}

	if (!isLoadSession) {
		return <Loading title="세션을 조회하고 있습니다." background="rgba(0, 0, 0, 0.1)" />;
	}

	const countDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			if (endDate) {
				const now = moment();
				const end = moment(endDate, "YYYY-MM-DD HH:mm:ss");
				if (now.isAfter(end)) {
					return <Waiting>강의가 종료되었습니다.</Waiting>;
				}
			}
			return <Waiting>강의를 준비중입니다.</Waiting>;
		}
		return (
			<Waiting>
				남은시간 : {days > 0 && `${days}일 `}
				{hours > 0 && `${hours}시간 `}
				{minutes}분 {seconds}초
			</Waiting>
		);
	};

	if (!session) {
		if (startDate) {
			return <Countdown date={moment(startDate, "YYYY-MM-DD HH:mm:ss").toDate()} renderer={countDownRenderer} />;
		}
		return <Waiting>강의를 준비중입니다.</Waiting>;
	}

	return (
		<Fragment>
			<Console>
				<CIWrapper>
					<OnAir>
						ON<span>AIR</span>
					</OnAir>
					{streams?.length > 1 && (
						<StreamListWrapper>
							{streams?.map((s, i) => (
								<StreamItemWrapper ison={nowStream.idx === s.idx} key={i} onClick={onSetNowStream.bind(this, s)}>
									{s.streamName}
								</StreamItemWrapper>
							))}
						</StreamListWrapper>
					)}
					<CIQuestion>
						<input type="text" value={question || ""} onChange={(e) => setQuestion(e.target.value)} placeholder="질문을 입력해 주세요." />
						<button type="button" onClick={onQuestion}>
							질문하기
						</button>
					</CIQuestion>
				</CIWrapper>
			</Console>
			{quiz && <Quiz {...{ siteIdx, siteEventIdx, siteEventSessionIdx: session.idx }} quiz={quiz} onSended={() => setQuiz(null)} />}
		</Fragment>
	);
};

const Quiz = (props) => {
	const { siteIdx, siteEventIdx, siteEventSessionIdx, quiz, onSended } = props;

	const onQuizClick = (answer) => {
		_API
			.post({
				path: "/v1/site/event/session/quiz",
				data: {
					siteIdx,
					siteEventIdx,
					siteEventSessionIdx,
					siteEventSessionQuizIdx: quiz.idx,
					answer,
				},
			})
			.then((res) => {
				swal({ title: "알림", text: "참여해 주셔서 감사합니다." }).then(() => {
					onSended && onSended();
				});
			});
	};

	return (
		<QContainer>
			<QWrapper>
				<QTitle dangerouslySetInnerHTML={{ __html: quiz.question }} />
				{quiz.answer1 && <QAnswer onClick={onQuizClick.bind(this, 1)}>{quiz.answer1}</QAnswer>}
				{quiz.answer2 && <QAnswer onClick={onQuizClick.bind(this, 2)}>{quiz.answer2}</QAnswer>}
				{quiz.answer3 && <QAnswer onClick={onQuizClick.bind(this, 3)}>{quiz.answer3}</QAnswer>}
				{quiz.answer4 && <QAnswer onClick={onQuizClick.bind(this, 4)}>{quiz.answer4}</QAnswer>}
				{quiz.answer5 && <QAnswer onClick={onQuizClick.bind(this, 5)}>{quiz.answer5}</QAnswer>}
				{quiz.answer6 && <QAnswer onClick={onQuizClick.bind(this, 6)}>{quiz.answer6}</QAnswer>}
				{quiz.answer7 && <QAnswer onClick={onQuizClick.bind(this, 7)}>{quiz.answer7}</QAnswer>}
				{quiz.answer8 && <QAnswer onClick={onQuizClick.bind(this, 8)}>{quiz.answer8}</QAnswer>}
				{quiz.answer9 && <QAnswer onClick={onQuizClick.bind(this, 9)}>{quiz.answer9}</QAnswer>}
				{quiz.answer10 && <QAnswer onClick={onQuizClick.bind(this, 10)}>{quiz.answer10}</QAnswer>}
			</QWrapper>
		</QContainer>
	);
};

/**** Styled-Components *******************/
const Waiting = styled.div`
	margin: 1rem 0rem;
	padding: 1rem;
	background: #ffffff;
	border-radius: 0.5rem;
	text-align: center;
	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		margin: 1rem;
		padding: 1rem;
		border-radius: 0.5rem;
		background: #ffffff;
	}
`;

const Console = styled.div`
	margin-top: 1rem;

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		margin: 1rem 1rem 0;
	}
`;

const OnAir = styled.div`
	margin: 0 0 0.5rem;
	padding: 0.2rem 0.3rem;
	display: inline-block;
	background: red;
	color: #ffffff;
	border-radius: 0.3rem;
	font-size: 0.5rem;
	span {
		margin: 0 0 0 0.2rem;
		padding: 0.2rem;
		display: inline-block;
		color: red;
		background: #ffffff;
		border-radius: 0.3rem;
	}
`;

const CIWrapper = styled.div`
	margin: 1rem 0rem;
`;

const CIQuestion = styled.div`
	width: 100%;
	height: 3.125rem;
	font-size: 0.8rem;
	font-weight: normal;
	box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
	border: #333333 solid 1px;
	border-radius: 1.625rem;
	display: flex;
	overflow: hidden;

	input {
		padding: 0 1rem;
		font-size: 0.9rem;
		flex: 1;
		border: none;
	}
	button {
		border: none;
		flex-basis: 5rem;
		color: #ffffff;
		background: #333333;
	}
`;

const QContainer = styled.div`
	padding: 1rem;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;

	background: rgba(0, 0, 0, 0.7);
	border: none;
	z-index: 2000;
	align-items: center;
	justify-content: center;
`;

const QWrapper = styled.div`
	padding: 1rem;
	width: 100%;
	max-width: 30rem;
	border: #cccccc solid 1px;
	border-radius: 0.5rem;
	background: #ffffff;
`;

const QTitle = styled.div`
	margin: 0 0 1rem;
`;
const QAnswer = styled.button`
	margin: 0 0 0.5rem;
	padding: 0.5rem 0;
	width: 100%;
	display: block;
	border: #cccccc solid 1px;
	border-radius: 0.3rem;
`;

const StreamListWrapper = styled.div.attrs({
	className: "btn-group",
})`
	display: block;
	margin-bottom: 0.5rem;
`;
const StreamItemWrapper = styled.button.attrs((props) => {
	return {
		type: "button",
		className: props.ison ? "btn btn-success" : "btn btn-secondary",
	};
})``;
