import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";

import { ImageMap, Area } from "@qiuz/react-image-map";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _CFG } from "../../../modules/config";
import { _U, _CK } from "../../../modules/utils";

import Video from "../../../components/UI/Video";

import { Container, Wrapper, HeadWrapper, NavWrapper, CopyWrapper, Content } from "./Components";
import { WebinarWrapper, WebinarVisualWrapper, WebinarAgendaWrapper, WebinarContent } from "./Components";

import Login from "./Components.Login";
import Registration, { RegView, RegCheckView, RegDetailView } from "./Registration";

import { default as AgendaView } from "./Agenda";
import { default as LocationView } from "./Location";
import { default as FeedbackView } from "./Feedback";
import { default as SessionView } from "./Session";
import { default as BoothView, Booth as BoothDetailView } from "./Booth";

import Socket from "../../../components/UI/Socket";

export default (props) => {
    const { isLoginLogo } = props;
    const { cfg, auth } = useContext(AppContext);
    const { cfUrl, event } = cfg;
    const [playVideo, setPlayVideo] = useState(null);

    const hasMenu = event?.menu?.length > 0;
    const hasLogo = !(!event?.uiLogoPcFilePath || !event?.uiLogoMobileFilePath);

    const teaser = event?.uiTeaserUrl;
    const pc = event?.uiVisualPcFilePath ? `${cfUrl}${event?.uiVisualPcFilePath}` : "";
    const mobile = event?.uiVisualMobileFilePath ? `${cfUrl}${event?.uiVisualMobileFilePath}` : "";
    const hasVisual = (pc && mobile) || teaser;
    const isLogin = _CK.isLogin();

    const pcAgenda = event?.uiAgendaPcFilePath ? `${cfUrl}${event?.uiAgendaPcFilePath}` : "";
    const mobileAgenda = event?.uiAgendaMobileFilePath ? `${cfUrl}${event?.uiAgendaMobileFilePath}` : "";
    const hasAgenda = pcAgenda && mobileAgenda;

    useEffect(() => {
        if (event) {
            setBackground(event, cfg.cfUrl);
        }

        return () => {
            clearBackground();
        };
    }, []);

    const location = event?.locations.length > 0 ? event.locations[0] : null;
    const isShowSession = isLogin && location;
    const isShowVisual = !isShowSession && hasVisual;

    const hlsConfig = {
        debug: _CFG.isLocal(),
        autoStartLoad: true,
        startPosition: -1,
    };

    const video = ["https://cdn.vmcasting.co.kr/2020/12/11/27e66c2fcc914f73bdcb765e6dc6a32e.mp4", "https://cdn.vmcasting.co.kr/2020/12/11/650988567227402c94c51447fcf15ac5.mp4", "https://cdn.vmcasting.co.kr/2020/12/11/fcba2b58124e448aa3c0297b0d77e33e.mp4", "https://cdn.vmcasting.co.kr/2020/12/11/f60e05236a134748b255cfe6bd71ca50.mp4"];

    const onAgendaMapClick = (area, index) => {
        if (isLogin) {
            setTimeout(() => {
                setPlayVideo(video[index]);
            }, 100);

            window.scrollTo(0, 0);
        }
    };

    console.log(playVideo);

    return (
        <Router>
            <Container {...{ hasMenu: isLogin && hasMenu, hasLogo: isLoginLogo ? isLogin && hasLogo : hasLogo }}>
                {isLoginLogo ? isLogin && <Header /> : <Header />}
                {isLogin && <Navigation />}

                <WebinarWrapper>
                    {isShowVisual && <WebinarVisualWrapper img={{ pc, mobile }}>{teaser ? teaser.indexOf("m3u8") !== 0 ? <Video url={teaser} autoPlay={true} poster={pc} /> : <iframe src={teaser} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen /> : <img alt="" />}</WebinarVisualWrapper>}

                    {isLogin && playVideo ? (
                        <WebinarVisualWrapper img={{ pc, mobile }}>
                            <Fragment>
                                {/* <iframe id="playVideo" src={playVideo} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen /> */}

                                <video id="video-container" src={playVideo} type="video/mp4" autoPlay allowFullScreen controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()} />
                            </Fragment>
                        </WebinarVisualWrapper>
                    ) : (
                        <Fragment>
                            {isLogin && location && location.streamPlayUrl && (
                                <WebinarVisualWrapper img={{ pc, mobile }}>
                                    {location.streamPlayUrl.indexOf("m3u8") !== -1 ? (
                                        <Fragment>
                                            <Video url={location.streamPlayUrl} autoPlay={true} isLive />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <iframe src={location.streamPlayUrl} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                        </Fragment>
                                    )}
                                </WebinarVisualWrapper>
                            )}
                        </Fragment>
                    )}

                    {isShowSession && <SessionView location={location.idx} />}

                    <WebinarContent className="wrapper">
                        <WebinarContent className="content left">
                            <Login
                                reset={() => {
                                    setPlayVideo(null);
                                }}
                            />
                            <Registration />
                        </WebinarContent>
                        <WebinarContent className="content right">
                            <Content code="home" />
                        </WebinarContent>
                    </WebinarContent>
                </WebinarWrapper>
                <Switch>
                    <Route path="/agenda" component={AgendaView} />
                    <Route path="/location" component={LocationView} />
                    <Route path="/survey" component={FeedbackView} />
                    <Route path="/feedback" component={FeedbackView} />
                    <Route path="/registration/check" component={RegCheckView} />
                    <Route path="/registration/view" component={RegDetailView} />
                    <Route path="/registration/modify" component={RegView} />
                    <Route path="/registration" component={RegView} />
                    <Route path="/booth" exact component={BoothView} />
                    <Route path="/booth/:code" component={BoothDetailView} />
                    <Route path="/:code" component={ContentView} />
                    <Redirect path="*" to="/" />
                </Switch>
                {hasAgenda && (
                    <WebinarAgendaWrapper img={{ pc: pcAgenda, mobile: mobileAgenda }}>
                        <ImageMap
                            src={isMobile ? mobileAgenda : pcAgenda}
                            map={[
                                { width: "89.23632385120351%", height: "12.668896321070235%", left: "5.470459518599562%", top: "24.729096989966553%", href: "" },
                                { width: "89.27789934354486%", height: "11.872909698996656%", left: "5.788429978118162%", top: "36.45484949832776%", href: "" },
                                { width: "88.7308533916849%", height: "9.03010033444816%", left: "5.56961159737418%", top: "63.7123745819398%", href: "" },
                                { width: "89.16849015317287%", height: "9.698996655518394%", left: "5.679020787746171%", top: "73.24414715719062%", href: "" },
                            ]}
                            onMapClick={onAgendaMapClick}
                        />
                    </WebinarAgendaWrapper>
                )}
                <Copyright />
                {(auth || cfg.event?.useLogin !== "Y") && <Socket />}
            </Container>
        </Router>
    );
};

const ContentView = (props) => {
    const { code } = props.match.params;
    return (
        <Wrapper>
            <Content code={code} />
        </Wrapper>
    );
};

const Header = (props) => {
    const { cfg } = useContext(AppContext);
    const { cfUrl, event } = cfg;

    const bgPc = event?.uiLogoPcFilePath ? `${cfUrl}${event?.uiLogoPcFilePath}` : "";
    const bgMobile = event?.uiLogoMobileFilePath ? `${cfUrl}${event?.uiLogoMobileFilePath}` : "";
    if (!bgPc || !bgMobile) {
        return null;
    }

    return (
        <HeadWrapper bg={{ pc: bgPc, mobile: bgMobile }}>
            <Link to="/"></Link>
        </HeadWrapper>
    );
};

const Navigation = (props) => {
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    const [menu, setMenu] = useState("");

    const activeMenu = () => {
        const { location } = window;
        if (!location) {
            return;
        }
        const { hash } = location;
        if (!hash) {
            return;
        }
        setMenu(hash.replace("#/", ""));
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "LOCATION-CHANGED":
                    activeMenu();
                    break;
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [location]);

    if (event?.menu?.length === 0) {
        return null;
    }

    return (
        <NavWrapper bg={event?.uiBgColor}>
            <ul>
                {/* <li className="home">
                    <Link to="/" className={cx({ active: !menu })}>
                        HOME
                    </Link>
                </li> */}
                {event?.menu?.map((item, i) => (
                    <li key={i}>
                        <Link to={`/${item.value}`} className={cx({ active: item.value === menu })}>
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </NavWrapper>
    );
};

const Copyright = (props) => {
    const { cfg } = useContext(AppContext);
    const { cfUrl, event } = cfg;

    const pc = event?.uiCopyPcFilePath ? `${cfUrl}${event?.uiCopyPcFilePath}` : "";
    const mobile = event?.uiCopyMobileFilePath ? `${cfUrl}${event?.uiCopyMobileFilePath}` : "";
    if (!pc || !mobile) {
        return null;
    }

    return (
        <CopyWrapper img={{ pc, mobile }}>
            <img alt="" />
        </CopyWrapper>
    );
};

/**** Others *****************************************/

const setBackground = (e, url) => {
    const b = $("body");
    if (e.uiBgColor) {
        b.css("background-color", e.uiBgColor);
    }
    if (isMobile) {
        if (e.uiBgMobileFilePath) {
            b.css("background-image", `url(${url}${e.uiBgMobileFilePath})`);
            b.css("background-repeat", "no-repeat");
            b.css("background-size", "cover");
        }
    } else {
        if (e.uiBgPcFilePath) {
            b.css("background-image", `url(${url}${e.uiBgPcFilePath})`);
            b.css("background-repeat", "no-repeat");
            b.css("background-size", "cover");
        }
    }
};
const clearBackground = () => {
    $("body").css("background", "#ffffff");
    $("body").css("background-image", "none");
};
