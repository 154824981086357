import _CK from "../utils/cookie";
import _U from "../utils/utilities";

const config = {
    apiKey: "fRBU6-&armD9Fb?B6)~8!UhD",
};
config.apiHost = () => {
    if (config.isLocal()) {
        return "http://localhost:3100";
    }
    return "https://api.vmcasting.co.kr";
};
config.isLocal = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return true;
    } else {
        return false;
    }
};
config.wsHost = () => {
    const authors = {
        apikey: config.apiKey,
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }
    if (config.isLocal()) {
        return `ws://localhost:3300?auth=${_U.encode(authors)}`;
    }
    return `wss://ws.vmcasting.co.kr?auth=${_U.encode(authors)}`;
};
config.google = {
    maps: {
        apiKey: "AIzaSyByU4xgpSzrDltOxZQPCRCrml94ZtiXyow",
    },
};

export default config;
