import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";

import { Wrapper as Container, Content, InputForm, SelectForm, ButtonForm, InputViewForm, ButtonFormGroup, HRDash, H2 } from "./Components";
import { _API, _U, _CK } from "../../../modules/utils";

import CustomForm, { CustomRegCheckView, CustomRegDetailView } from "./Components.Registration";

export default (props) => {
	const { onRegistration, onRegistrationCheck } = props;
	const { cfg } = useContext(AppContext);

	const isRegistration = () => {
		if (cfg.event?.preRegStartDate && cfg.event?.preRegEndDate) {
			if (moment(cfg.event?.preRegStartDate) <= moment() && moment(cfg.event?.preRegEndDate) > moment()) {
				return true;
			}
		}
		return false;
	};

	if (!isRegistration() || _CK.isLogin()) {
		return null;
	}

	return (
		<PreWrapper>
			<PreButton onClick={onRegistration}>
				<i className="fa fa-edit"></i> 사전등록
			</PreButton>
			<PreButton onClick={onRegistrationCheck}>
				<i className="fa fa-check"></i> 사전등록확인
			</PreButton>
		</PreWrapper>
	);
};

export const RegView = (props) => {
	const { code, history, location, match, onComplete, onCancel } = props;

	const { cfg } = useContext(AppContext);
	const [idx, setIdx] = useState(null);
	const [item, setItem] = useState({});

	const sessionCode = "_REGISTRATION_MODIFY";

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");

		// if (!location?.pathname.includes("modify")) {
		//     _U.removeSession(sessionCode);
		// }

		const session = _U.getSession(sessionCode);

		if (session) {
			if (session.code === sessionCode && session && session.idx) {
				setIdx(session.idx);
			}
		}
		_U.removeSession(sessionCode);
	}, []);

	const loadData = () => {
		if (idx) {
			const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

			_API
				.get({
					path: "/v1/site/event/registration/item",
					data: data,
				})
				.then((res) => {
					if (res.error === 0) {
						setItem(res.data);
					} else {
						swal(res.message);
					}
				});
		}
	};

	useEffect(() => {
		loadData();
	}, [idx]);

	// const onComplete = () => {
	//     history?.push("/registration/check");
	// };

	// const onCancel = () => {
	//     history?.goBack();
	// };

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>{idx ? `REGISTRATION 정보 수정` : `REGISTRATION`}</H2>
				<Warning>* 하기 정보 입력 후 Symposium 참석이 가능합니다.</Warning>
				{cfg.event?.preRegForm === "CUSTOM" ? (
					<CustomForm onComplete={onComplete} onCancel={onCancel} item={item} history={history} />
				) : (
					<Form onComplete={onComplete} onCancel={onCancel} item={item} />
				)}
			</Wrapper>
		</Container>
	);
};

const Form = (props) => {
	const { cfg } = useContext(AppContext);
	const [item, setItem] = useState({});

	useEffect(() => {
		if (props.item) {
			setItem(props.item);
		}
	}, [props.item]);

	const onSetItem = (e) => {
		const target = e.target;

		if (target.name === "agree") {
			if (target.checked) {
				setItem((o) => {
					return { ...o, agree: "Y" };
				});
			} else {
				setItem((o) => {
					return { ...o, agree: "N" };
				});
			}
		} else {
			let value = target.value;

			if (target.name === "mobile") {
				value = value.replace(/-/g, "");
			}

			setItem((o) => {
				return { ...o, [target.name]: value };
			});
		}
	};

	const getTeamWareSize = () => {
		let size = cfg.event?.preRegTeamWareSize;

		if (size) {
			size = size.split(",");

			const items = [];
			for (let i in size) {
				items.push({
					label: size[i],
					value: size[i],
				});
			}
			return <SelectForm title="팀복 사이즈" name="teamWareSize" nullTitle="선택" items={items} value={item.teamWareSize || ""} onChange={onSetItem} />;
		}

		return "";
	};

	const onSave = () => {
		if (!item.name) {
			swal("성명을 입력해주세요.");
			return false;
		}
		// if (!item.email) {
		//     swal("이메일을 입력해주세요.");
		//     return false;
		// }
		if (!item.mobile) {
			swal("휴대폰을 입력해주세요.");
			return false;
		}
		if (!item.company) {
			swal("병원명을 입력해주세요.");
			return false;
		}
		if (item.agree !== "Y") {
			swal("개인정보 활용에 동의하여 주세요.");
			return false;
		}

		const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

		_API
			.post({
				path: "/v1/site/event/registration",
				data: data,
			})
			.then((res) => {
				swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
					props.onComplete && props.onComplete();
				});
			});
	};

	const onBack = () => {
		props.onCancel && props.onCancel();
	};

	return (
		<Fragment>
			<InputForm title="이름" name="name" value={item.name || ""} onChange={onSetItem} />
			{/* <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} /> */}
			<InputForm title="휴대폰 번호(ID)" name="mobile" value={item.mobile || ""} onChange={onSetItem} />
			<InputForm title="병원명" name="company" value={item.company || ""} onChange={onSetItem} />
			{/* <InputForm title="직책" name="position" value={item.position || ""} onChange={onSetItem} />
            <InputForm title="부서명" name="department" value={item.department || ""} onChange={onSetItem} /> */}
			{getTeamWareSize()}
			<HRDash />
			<div style={{ fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.8rem", lineHeight: "1rem" }}>
				<label>
					<input type="checkbox" name="agree" value="Y" onChange={onSetItem} />
					&nbsp; (필수) 본 사이트에서 제공하는 서비스 이용과 관련한 개인정보 활용에 대해 동의합니다.
				</label>
			</div>
			<Wrapper>
				<Content code="registration" />
			</Wrapper>
			<HRDash />
			<ButtonFormGroup>
				<ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
				<ButtonForm title="취소" type="RESET" onClick={onBack} />
			</ButtonFormGroup>
		</Fragment>
	);
};

export const RegCheckView = (props) => {
	const { cfg } = useContext(AppContext);
	const { event } = cfg;

	if (event.preRegForm === "CUSTOM") {
		return <CustomRegCheckView {...props} />;
	} else {
		return <OriRegCheckView {...props} />;
	}
};

const OriRegCheckView = (props) => {
	const { code, history, onComplete, onCancel } = props;
	const { cfg } = useContext(AppContext);

	const [item, setItem] = useState({});

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	const onSetItem = (e) => {
		const target = e.target;
		setItem((o) => {
			return { ...o, [target.name]: target.value };
		});
	};

	const onBack = () => {
		history?.push("/registration");
	};

	const onSave = () => {
		if (!item.name) {
			swal("성명을 입력해주세요.");
			return false;
		}
		// if (!item.email) {
		//     swal("이메일을 입력해주세요.");
		//     return false;
		// }
		if (!item.mobile) {
			swal("휴대폰을 입력해주세요.");
			return false;
		}

		const data = { ...item, type: "WITH-OUT-EMAIL", siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

		_API
			.get({
				path: "/v1/site/event/registration/check",
				data: data,
			})
			.then((res) => {
				if (res.error === 0) {
					// window.sessionStorage.setItem("_REGISTRATION_CHECK", res.data);
					_U.setSession("_REGISTRATION_CHECK", res.data);
					// history?.push("/registration/view");
					onComplete();
				} else {
					swal(res.message);
				}
			});
	};

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");
	}, []);

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>REGISTRATION 조회</H2>
				<InputForm title="이름" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 성명을 입력해주세요." />
				{/* <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이메일을 입력해주세요." /> */}
				<InputForm title="휴대폰 번호(ID)" name="mobile" value={item.mobile || ""} onChange={onSetItem} placeholder="사전등록시 입력한 휴대전화번호를 입력해주세요." />
				<HRDash />
				<ButtonFormGroup>
					<ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
					<ButtonForm title="취소" type="RESET" onClick={onCancel} />
				</ButtonFormGroup>
			</Wrapper>
		</Container>
	);
};

export const RegDetailView = (props) => {
	const { cfg } = useContext(AppContext);
	const { event } = cfg;

	if (event.preRegForm === "CUSTOM") {
		return <CustomRegDetailView {...props} />;
	} else {
		return <OriRegDetailView {...props} />;
	}
};

const OriRegDetailView = (props) => {
	const { code, history, onComplete, onCancel } = props;
	const { cfg } = useContext(AppContext);
	const [idx, setIdx] = useState(null);
	const [item, setItem] = useState({});

	const sessionCode = "_REGISTRATION_CHECK";

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	useEffect(() => {
		const session = _U.getSession(sessionCode);
		if (!session) {
			onBack();
			return;
		}

		if (session.code !== sessionCode || !session || !session.idx) {
			onBack();
			return;
		}

		setIdx(session.idx);
	}, []);

	const loadData = () => {
		if (idx) {
			const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

			_API
				.get({
					path: "/v1/site/event/registration/item",
					data: data,
				})
				.then((res) => {
					if (res.error === 0) {
						setItem(res.data);
					} else {
						swal(res.message);
					}
				});
		}
	};

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");

		loadData();
	}, [idx]);

	// const onBack = () => {
	//     history?.push("/registration/check");
	// };

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>REGISTRATION 정보</H2>
				<InputViewForm title="이름" value={item?.name} />
				{/* <InputViewForm title="이메일" value={item?.email} /> */}
				<InputViewForm title="휴대폰 번호(ID)" value={item?.mobile} />
				<InputViewForm title="병원명" value={item?.company} />
				{/* <InputViewForm title="직책" value={item?.position} />
                <InputViewForm title="부서명" value={item?.department} /> */}
				{item?.teamWareSize && <InputViewForm title="팀복사이즈" value={item?.teamWareSize} />}
				<HRDash />
				<ButtonFormGroup>
					<ButtonForm
						title="수정"
						type="SUBMIT"
						onClick={() => {
							_U.setSession("_REGISTRATION_MODIFY", { code: "_REGISTRATION_MODIFY", idx: idx });
							//history?.push("/registration/modify");
							onComplete();
						}}
						primary
					/>
					<ButtonForm title="취소" type="RESET" onClick={onCancel} />
				</ButtonFormGroup>
			</Wrapper>
		</Container>
	);
};

/* Styled Components ****************************/

const Warning = styled.div`
	font-size: 0.8rem;
	color: #ed5565;
	margin-bottom: 1rem;
`;

const PreWrapper = styled.div`
	display: flex;
	button:nth-child(odd) {
		margin-right: 0.5rem;
	}
	button:nth-child(even) {
		margin-left: 0.5rem;
	}

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		margin: 1rem;
	}
`;
const PreButton = styled.button`
	width: 100%;
	height: 3.125rem;
	font-size: 0.8rem;
	font-weight: normal;
	text-align: center;
	line-height: 3.125rem;

	border-radius: 1.625rem;
	color: #333333;
	border: #ffffff solid 1px;
	background: #ffffff;
	box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
`;

const Wrapper = styled.div`
	width: 100%;
	margin: 1rem auto;
	padding: 1rem;
	background: #ffffff;
	border-radius: 0.5rem;

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		width: 100%;
		margin: 0rem;
		padding: 0rem;
		border-radius: none;
	}
`;
